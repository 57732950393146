import React from "react";
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../layouts/layout.js";
import Seo from "../components/seo.js";
import SectionBottomCta from "../components/SectionBottomCta.js";

import { SectionH2, SectionHeaderOnlyGray } from "../styles/style.js";

const TestimonialsPage = () => (
  <Layout>
    <Seo
      title="Testimonials"
    />

    <div className="relative z-0" style={{ display: "grid", background: "#000000" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.9",
          height: 500,
        }}
        alt="Amazing Limos Reviews Testimonials"
        src="../img/header-testi-bg.jpg"
        formats={["auto", "webp", "avif"]}
        width={1920}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 max-w-screen-md mx-auto">
          <div className="text-white text-5xl md:text-7xl font-extrabold text-center">Testimonials</div>
        </div>
      </div>
    </div>

    <SectionHeaderOnlyGray>
      <SectionH2>Amazing Words From Customers Like You</SectionH2>
    </SectionHeaderOnlyGray>

    <div className="relative" style={{ display: "grid", background: "#000000" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.7",
        }}
        className="2xl:h-screen"
        alt="party limo"
        src="../img/section-testi-bg.jpg"
        formats={["auto", "webp", "avif"]}
        width={1920}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 grid grid-cols-1 xl:grid-cols-3 gap-8 py-12 mx-auto">
          <div className="bg-white opacity-90 p-12 rounded-md">
            <p className="text-lg text-black">
              <em>"I've finally found a limo service that lived up to the reviews. If you need a limo, look no further!! This is by far the best Limousine Service I've ever used. I called Art for a last minute Limo and he couldn't have been nicer or more accommodating. I used the service for a concert in Hollywood and from the time they picked us up until they dropped us off 8 hours later, the service was impeccable. The limo was clean, Julio the driver was the best driver I've ever had, he was professional, funny, courteous and even more important, punctual. I won't even think about calling another service in the future. Thanks Art (and Julio) for making our night a great one!!"</em><br/><br/><span style={{ fontSize: `14px`, color: `#32325d` }}>--David L. from Fullerton, CA</span>
            </p>
          </div>
          <div className="bg-white opacity-90 p-12 rounded-md">
            <p className="text-lg text-black">
              <em>"Art and Amazing Limousines are hands down the best of the best! We hired a limo for our wedding in Oak Glen, Ca. to take us to Los Angeles for our wedding night. We needed to make a stop to pick up our luggage before being taken to L.A., as we were leaving for Maui early the next morning, and they were so accommodating! We had taken our card box with us in the limo only to realize that we couldn't get it back home or fit it in a suit case, Art took the box and kept it in his office where my mother-in-law was able to pick it up that week. Talk about helpful! We are so grateful Art for making the end of our wedding and trip to start our honeymoon stress free. We would highly recommend this company to everyone!"</em><br/><br/><span style={{ fontSize: `14px`, color: `#32325d` }}>--Kayla P. from Redlands, CA</span>
            </p>
          </div>
          <div className="bg-white opacity-90 p-12 rounded-md">
            <p className="text-lg text-black">
              <em>"Great service at Amazing Limousines from booking all the way to the day of the actual event. Our initial limo service cancelled on us sighting that their cars won't make it uphill to our church thus leaving us in a dilemma. Art stepped up to the challenge when we explained the situation and took the extra steps to check the church location and actually making a test drive to ensure we have an amazing smooth drive to our wedding ceremony. Gabriel the driver was prompt on time, so helpful and extremely professional. Thanks to Art and Gabriel we had a great amazing ride all around. Thank you!!!"</em><br/><br/><span style={{ fontSize: `14px`, color: `#32325d` }}>--Martina S. from West Covina, CA</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <SectionBottomCta grayBackground />
  </Layout>
);

export default TestimonialsPage